<template>
  <v-container v-if="$role(['manager', 'svp'], $store.state.user.role)" class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-2">
        <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height primary" dark outlined @click.prevent="$role([], $store.state.user.role) ? STORE_SHOW() : null">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Tambah Data
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-8">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search data ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-select
            v-model.number="storeType"
            :items="[
              { value: -1, name: 'Semua Data' },
              { value: 0, name: 'Cabang' },
              { value: 1, name: 'Vendor' }
            ]"
            item-value="value"
            item-text="name"
            outlined
            dense
            hide-details
            label="Jenis"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #{{ (item.code || 'NAN') }} - {{ ('000000' + item.id).slice(-6) }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2">
              <div class="d-flex align-center">
                <v-chip :color="parseInt(item.type) ? 'secondary' : (parseInt(item.id) ? 'orange' : 'primary')" dark x-small class="mr-1">
                  {{ parseInt(item.type) ? 'Vendor' : (parseInt(item.id) ? 'Cabang' : 'Pusat') }}
                </v-chip>
                {{ item.name }}
                <!-- {{ item.name }} {{ item.type_shipping ? ('(' + item.type_shipping + ')') : '' }} -->
              </div>
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div>
              {{ item.phone }}
            </div>
          </template>
          <template v-slot:[`item.city_name`]="{ item }">
            <div>
              <span v-if="item.city_name">
                {{ item.city_name }}, {{ item.city_type }} - {{ item.province_name }}
              </span>
              <span v-else class="red--text caption-small">
                -
              </span>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager', 'svp'], $store.state.user.role) && (parseInt(item.type) || (!parseInt(item.type) && !parseInt(item.id)))" depressed small dark color="purple" class="text-capitalize mr-2" @click.prevent="PRICING_SHOW(item)">
                <v-icon small class="mr-1">
                  mdi-cash-multiple
                </v-icon>
                Pricing
              </v-btn>
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="text-capitalize" @click.prevent="STORE_SHOW(item)">
                <v-icon small class="mr-1">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogStore.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogStore.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogStore.data.id ? 'Update Data' : 'Tambah Data' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <!-- <div v-if="parseInt(dialogStore.data.id) === 1" class="py-2 d-flex fill-width justify-center" style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogStore.data.welcome_banner"
              :aspect-ratio="1/1"
              class="blue lighten-4"
              style="max-width:200px;"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogStore.data.welcome_banner ? 'Change Welcome Banner' : 'Upload Welcome Banner' }}
              </span>
            </div>
          </div> -->
          <v-select
            v-model.number="dialogStore.data.type"
            :items="[
              { name: 'Default / Cabang', value: 0 },
              { name: 'Vendor / Eksternal', value: 1 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Type"
          />
          <v-text-field
            v-model="dialogStore.data.name"
            outlined
            dense
            hide-details
            :label="'Nama ' + ((dialogStore.data.type) ? 'Vendor' : 'Cabang')"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogStore.data.code"
            :disabled="parseInt(dialogStore.data.id) === 0 || parseInt(dialogStore.data.id) ? true : false"
            outlined
            dense
            hide-details
            :label="'Kode ' + ((dialogStore.data.type) ? 'Vendor' : 'Cabang')"
            class="mb-4"
          />
          <!-- <v-select
            v-model="dialogStore.data.type_shipping"
            :items="[
              { name: 'LANDPORT', value: 'LANDPORT' },
              { name: 'SEAPORT', value: 'SEAPORT' },
              { name: 'AIRPORT', value: 'AIRPORT' },
              { name: '- NOT SET -', value: '' }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Shipping Type"
          /> -->
          <v-text-field
            v-model="dialogStore.data.phone"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp"
            class="mb-4"
            @keypress="$NumOnly"
          />
          <v-autocomplete
            v-model.number="dialogStore.data.city_id"
            :name="Math.random()"
            :search-input.sync="areaquery"
            :items="areas"
            item-value="city_id"
            item-text="area_name"
            outlined
            dense
            hide-details
            label="Area (Kota, Provinsi)"
            class="mb-4"
            autocomplete="disabled"
          />
          <v-textarea
            v-model="dialogStore.data.description"
            outlined
            dense
            hide-details
            label="Description"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogStore.data.status"
            :items="[
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogStore.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="STORE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPricing.show"
      persistent
      scrollable
      max-width="1200"
    >
      <v-card v-if="dialogPricing.selected">
        <v-card-title class="body-1 justify-center text-uppercase">
          {{ dialogPricing.selected.name }} - PRICING
        </v-card-title>
        <v-card-text class="pt-4" style="max-height: 90vh;">
          <v-divider class="my-4" />
          <v-row align="center" justify="start" class="grey lighten-2 py-2">
            <v-col cols="3">
              <v-autocomplete
                v-model.number="newShippingOrigin"
                :name="Math.random()"
                :search-input.sync="newShippingOriginQuery"
                :items="newShippingOriginData"
                :disabled="$role(['manager', 'svp'], $store.state.user.role) ? false : true"
                item-value="city_id"
                item-text="area_name"
                outlined
                dense
                hide-details
                label="- Asal Pengiriman -"
                autocomplete="disabled"
              />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model.number="newShippingDestination"
                :name="Math.random()"
                :search-input.sync="newShippingDestinationQuery"
                :items="newShippingDestinationData"
                item-value="city_id"
                item-text="area_name"
                outlined
                dense
                hide-details
                label="- Tujuan Pengiriman -"
                autocomplete="disabled"
              />
            </v-col>
            <v-col cols="12" class="col-sm-2">
              <v-text-field
                v-model.number="newShippingPrice"
                hide-details
                dense
                outlined
                label="Harga"
                @keypress="$NumOnly"
              />
            </v-col>
            <!-- <v-col cols="12" class="col-sm-2">
              <v-select
                v-model="newShippingType"
                :items="['KG','UNIT']"
                hide-details
                dense
                outlined
                label="Satuan"
              />
            </v-col> -->
            <v-col cols="12" class="col-sm-4">
              <v-text-field
                v-model.number="newShippingNotes"
                hide-details
                dense
                outlined
                label="Catatan"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogPricing.product"
                :append-outer-icon="$role(['manager'], $store.state.user.role) ? (parseInt(dialogPricing.product) ? 'mdi-pencil' : 'mdi-plus') : ''"
                :items="productData.concat($role(['svp'], $store.state.user.role) ? [] : [{ name: '- Add New -', id: 0 }])"
                item-text="name"
                item-value="id"
                hide-details
                dense
                outlined
                label="Pilih Produk"
                @click:append-outer="SHIPPING_PRODUCT_SHOW()"
              />
            </v-col>
            <v-col v-if="!parseInt(dialogPricing.selected.type)" cols="12" class="col-sm-4">
              <v-autocomplete
                v-model.number="dialogPricing.customer"
                :name="Math.random()"
                :search-input.sync="customerQuery"
                :items="customerData"
                item-value="id"
                item-text="customer_detail"
                outlined
                dense
                hide-details
                label="- Cari Customer -"
                clearable
                autocomplete="disabled"
              />
            </v-col>
            <v-col cols="12" :class="[parseInt(dialogPricing.selected.type) ? '' : 'col-sm-4']">
              <v-btn depressed block color="purple" class="text-capitalize font-weight-bold white--text" @click.prevent="PRICING_PROCESS()">
                Tambah Data
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row class="mb-4">
            <v-col cols="12" :class="[parseInt(dialogPricing.selected.type) ? 'col-sm-6' : 'col-sm-3']">
              <v-autocomplete
                v-model.number="dialogPricing.shipping_origin"
                :name="Math.random()"
                :search-input.sync="shippingOriginQuery"
                :items="shippingOriginData"
                :disabled="$role(['manager', 'svp'], $store.state.user.role) ? false : true"
                item-value="city_id"
                item-text="area_name"
                outlined
                dense
                hide-details
                label="- Asal Pengiriman -"
                autocomplete="disabled"
              />
            </v-col>
            <v-col cols="12" :class="[parseInt(dialogPricing.selected.type) ? 'col-sm-6' : 'col-sm-3']">
              <v-autocomplete
                v-model.number="dialogPricing.shipping_destination"
                :name="Math.random()"
                :search-input.sync="shippingDestinationQuery"
                :items="shippingDestinationData"
                item-value="city_id"
                item-text="area_name"
                outlined
                dense
                hide-details
                label="- Tujuan Pengiriman -"
                autocomplete="disabled"
              />
            </v-col>
            <v-col v-if="parseInt(dialogPricing.selected.type)" cols="12" class="col-sm-3">
              <v-select
                v-model.number="dialogPricing.productFilter"
                :items="productData.concat([{ name: 'All Product', id: 0 }])"
                item-text="name"
                item-value="id"
                hide-details
                dense
                outlined
                label="Filter by Produk"
                @change="LoadPricing(dialogPricing.selected)"
              />
            </v-col>
            <v-col v-if="!parseInt(dialogPricing.selected.type)" cols="12" class="col-sm-3">
              <v-autocomplete
                v-model.number="dialogPricing.customerFilter"
                :name="Math.random()"
                :search-input.sync="customerQueryFilter"
                :items="[{ id: 0, customer_detail: '- Semua Customer -' }].concat(customerDataFilter)"
                item-value="id"
                item-text="customer_detail"
                outlined
                dense
                hide-details
                label="- Cari Customer -"
                autocomplete="disabled"
                @change="LoadPricing(dialogPricing.selected)"
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-row v-if="!(dialogPricing.data && dialogPricing.data.length)" justify="center" align="center" class="grey--text">
            <v-col cols="12" class="text-center caption pa-12">
              - No Data -
            </v-col>
          </v-row>
          <v-card v-if="loading_pricing" class="pa-8">
            Loading
          </v-card>
          <div v-else>
            <v-row v-for="(p, iP) in dialogPricing.data" :key="'pricing-' + iP" align="center" class="grey lighten-4" style="border-bottom: solid 3px #fff!important;">
              <v-col cols="12" class="col-sm-3 font-weight-bold">
                <div class="d-flex justify-space-between align-center">
                  <div class="caption secondary--text text--darken-1 text-uppercase d-flex alig-center">
                    <v-icon class="mr-1" small color="green">
                      mdi-package
                    </v-icon>
                    {{ dialogPricing.data[iP].product_name || '-' }}
                    <span v-if="p.customer_name" class="mx-2 black--text">
                      |
                    </span>
                    <span v-if="p.customer_name" class="d-flex align-center orange--text">
                      <v-icon class="mr-1" small color="orange">
                        mdi-account
                      </v-icon>
                      {{ p.customer_name }}
                    </span>
                  </div>
                  <div class="d-flex align-center">
                    Est : <input v-model.lazy="dialogPricing.data[iP].est_to" type="text" class="ml-1 d-inline-block px-2 white rounded-lg text-right caption c-pointer" style="outline: none;max-width: 35px;" @keypress="$NumOnly" @keyup="parseInt(dialogPricing.data[iP].est_to) ? true : (dialogPricing.data[iP].est_to = 0)"> hari
                  </div>
                </div>
                <div>
                  From <strong>{{ p.city_origin_name || '-' }}</strong>
                </div>
                <div>
                  To <strong>{{ p.city_destination_name || '-' }}</strong>
                </div>
              </v-col>
              <v-col v-if="!parseInt(dialogPricing.selected.type)" cols="12" class="col-sm-2">
                <v-select
                  v-model.number="dialogPricing.data[iP].product"
                  :items="productData"
                  hide-details
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  label="Produk"
                />
              </v-col>
              <v-col cols="12" class="col-sm-2">
                <v-text-field
                  v-model.number="dialogPricing.data[iP].price"
                  hide-details
                  dense
                  outlined
                  suffix="/kg"
                  label="Harga"
                  @keypress="$NumOnly"
                />
              </v-col>
              <v-col cols="12" class="col-sm-2">
                <v-text-field
                  v-model.number="dialogPricing.data[iP].weight_minimum_kg"
                  hide-details
                  dense
                  outlined
                  label="Weight Minimum"
                  suffix="Kg"
                  @keypress="$NumOnly"
                />
              </v-col>
              <!-- <v-col cols="12" class="col-sm-2">
                <v-select
                  v-model.number="dialogPricing.data[iP].product"
                  :items="productData"
                  item-text="name"
                  item-value="id"
                  hide-details
                  dense
                  outlined
                  label="Produk"
                />
              </v-col> -->
              <v-col cols="12" :class="parseInt(dialogPricing.selected.type) ? 'col-sm-4' : 'col-sm-2'">
                <v-text-field
                  v-model.number="dialogPricing.data[iP].notes"
                  hide-details
                  dense
                  outlined
                  label="Catatan"
                />
              </v-col>
              <v-col cols="12" class="col-sm-1 d-flex flex-row flex-sm-column align-center justify-center">
                <v-icon class="ma-1" color="indigo" @click.prevent="PricingUpdate(p)">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon class="ma-1" color="red" @click.prevent="PricingDelete(p)">
                  mdi-close-circle-outline
                </v-icon>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogPricing.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProduct.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogProduct.data" >
        <v-card-title class="body-1 justify-center">
          {{ parseInt(dialogProduct.data.id) ? 'Update Data' : 'Tambah Data' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-select
            v-model="dialogProduct.data.type"
            :items="[
              'LANDPORT',
              'SEAPORT',
              'AIRPORT',
              'INTERNATIONAL'
            ]"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Tipe Produk"
          />
          <v-text-field
            v-model="dialogProduct.data.name"
            outlined
            dense
            hide-details
            label="Nama Produk"
            class="mb-4"
          />
          <v-text-field
            v-model.number="dialogProduct.data.div_amount"
            outlined
            dense
            hide-details
            label="Nilai Pembagi Metrik"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogProduct.data.is_unit"
            :items="[{ name: 'KG', value: 0 }, { name: 'UNIT', value: 1 }]"
            outlined
            item-text="name"
            item-value="value"
            dense
            hide-details
            class="mb-4"
            label="Satuan"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogProduct.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="SHIPPING_PRODUCT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
let searchAreaTimeout = null
let timeOutOrigin = null
let timeOutDestination = null
let timeOutOriginNew = null
let timeOutDestinationNew = null
let timeOutCustomer = null
let timeOutCustomerFilter = null
let loadingTimeout = null
export default {
  name: 'StoreManager',
  data: () => ({
    storeType: -1,
    headers: [
      {
        text: 'ID',
        value: 'id',
        sortable: false
      },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Phone', value: 'phone', sortable: false },
      { text: 'Area', value: 'city_name', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    customerQuery: '',
    customerData: [],
    customerQueryFilter: '',
    customerDataFilter: [],
    productData: [],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 100,
    tableSearchStatus: null,
    options: {},
    dialogStore: {
      show: false,
      data: null
    },
    dialogPricing: {
      show: false,
      loading: false,
      selected: null,
      shipping_origin: 0,
      shipping_destination: 0,
      product: 0,
      productFilter: 0,
      customer: 0,
      customerFilter: 0,
      data: []
    },
    shippingOriginQuery: '',
    shippingOriginData: [],
    shippingDestinationQuery: '',
    shippingDestinationData: [],
    areaquery: '',
    areas: [],
    loadingImageUpload: false,
    newShippingOrigin: 0,
    newShippingDestination: 0,
    newShippingPrice: 0,
    newShippingType: 'KG',
    newShippingNotes: '',
    newShippingOriginQuery: '',
    newShippingOriginData: [],
    newShippingDestinationQuery: '',
    newShippingDestinationData: [],
    dialogProduct: {
      show: false,
      data: null
    },
    loading_pricing: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    storeType () {
      this.STORE_GET(this.tableSearch)
    },
    customerQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutCustomer) {
        clearTimeout(timeOutCustomer)
      }
      timeOutCustomer = setTimeout(() => {
        this.CUSTOMER_GET()
      }, 300)
    },
    customerQueryFilter (v) {
      if (!v) {
        return false
      }
      if (timeOutCustomerFilter) {
        clearTimeout(timeOutCustomerFilter)
      }
      timeOutCustomerFilter = setTimeout(() => {
        this.CUSTOMER_GET(true)
      }, 300)
    },
    newShippingOriginQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutOriginNew) {
        clearTimeout(timeOutOriginNew)
      }
      timeOutOriginNew = setTimeout(() => {
        this.AREA_REQUEST(2)
      }, 300)
    },
    newShippingDestinationQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutDestinationNew) {
        clearTimeout(timeOutDestinationNew)
      }
      timeOutDestinationNew = setTimeout(() => {
        this.AREA_REQUEST(3)
      }, 300)
    },
    shippingOriginQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutOrigin) {
        clearTimeout(timeOutOrigin)
      }
      timeOutOrigin = setTimeout(() => {
        this.LoadPricing(this.dialogPricing.selected)
        this.AREA_REQUEST(true)
      }, 300)
    },
    shippingDestinationQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutDestination) {
        clearTimeout(timeOutDestination)
      }
      timeOutDestination = setTimeout(() => {
        this.LoadPricing(this.dialogPricing.selected)
        this.AREA_REQUEST()
      }, 300)
    },
    areaquery (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.STORE_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.STORE_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.STORE_GET(this.tableSearch)
      }
    }
  },
  created () {
    if (this.$store.state.user) {
      if (!this.$role(['manager', 'svp'], this.$store.state.user.role)) {
        this.$router.replace('/')
      }
    } else {
      this.$router.replace('/')
    }
  },
  mounted () {
    this.STORE_GET()
    this.SHIPPING_PRODUCT_GET()
  },
  methods: {
    CUSTOMER_GET (isFilter) {
      const q = '?q=' + (isFilter ? this.customerQueryFilter : this.customerQuery)
      this.$store.dispatch('customer/CUSTOMER_SEARCH', q).then((res) => {
        if (res.status) {
          if (isFilter) {
            this.customerDataFilter = res.data.data.data || []
          } else {
            this.customerData = res.data.data.data || []
          }
        }
      })
    },
    SHIPPING_PRODUCT_GET () {
      this.productData = []
      this.$store.dispatch('logistic/SHIPPING_PRODUCT_GET')
        .then((res) => {
          if (res) {
            if (res.status) {
              this.productData = res.data.data || []
            }
          }
        })
    },
    SHIPPING_PRODUCT_SHOW () {
      let product = {
        name: '',
        type: 'LANDPORT',
        is_unit: 0,
        div_amount: 4000
      }
      const productSelected = parseInt(this.dialogPricing.product)
      if (productSelected) {
        const productFind = this.productData.find(r => parseInt(r.id) === productSelected)
        if (productFind) {
          product = Object.assign({}, productFind)
        }
      }
      this.dialogProduct.data = Object.assign({}, product)
      this.dialogProduct.show = true
    },
    SHIPPING_PRODUCT_PROCESS () {
      const params = Object.assign({}, this.dialogProduct.data)
      if (!params.id) {
        delete params.id
      }
      if (!params.type) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tipe produk belum dipilih' })
        return false
      }
      if (!params.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama produk belum diisi!' })
        return false
      }
      if (!parseInt(params.div_amount)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nilai pembagi metrik belum diisi!' })
        return false
      }
      if (!confirm(parseInt(params.id) ? 'Update data ?' : 'Tambah data ?')) {
        return false
      }
      this.$store.dispatch('logistic/SHIPPING_PRODUCT_PROCESS', params)
        .then((res) => {
          if (res.status) {
            this.SHIPPING_PRODUCT_GET()
            this.LoadPricing(this.dialogPricing.selected)
            this.ResetNewShippingPrice()
            this.dialogProduct.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil!' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong!' })
          }
        })
    },
    async PRICING_SHOW (item) {
      this.ResetNewShippingPrice()
      this.dialogPricing.data = []
      this.dialogPricing.selected = Object.assign({}, item)
      this.dialogPricing.product = parseInt(item.product) || 0
      this.dialogPricing.customer = 0
      this.dialogPricing.customerFilter = 0
      this.dialogPricing.show = true
      this.dialogPricing.loading = false
      if (item && parseInt(item.city_id) && item.area_name && this.$store.state.user.role === 'svp') {
        this.newShippingOrigin = parseInt(item.city_id)
        this.newShippingOriginQuery = item.area_name
        this.AREA_REQUEST(2, item.area_name)

        this.dialogPricing.shipping_origin = parseInt(item.city_id)
        this.shippingOriginQuery = item.area_name
        this.LoadPricing(this.dialogPricing.selected)
        this.AREA_REQUEST(true, item.area_name)
      }
      console.log(this.newShippingOrigin, this.newShippingOriginQuery)
      this.LoadPricing(item)
    },
    PricingDelete (p) {
      if (!confirm('Delete this price ?')) {
        return false
      }
      this.$store.dispatch('logistic/SHIPPING_PRICING_DELETE', p)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Deleted!' })
            this.LoadPricing(this.dialogPricing.selected)
            this.ResetNewShippingPrice()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    PricingUpdate (p) {
      const data = Object.assign({}, p)
      data.weight_minimum = (parseFloat(data.weight_minimum_kg) * 1000) || 0
      delete data.weight_minimum_kg
      this.$store.dispatch('logistic/SHIPPING_PRICING_PROCESS', data)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Updated!' })
            this.LoadPricing(this.dialogPricing.selected)
            this.ResetNewShippingPrice()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    PRICING_PROCESS () {
      const params = {
        id: null,
        customer: parseInt(this.dialogPricing.customer) || 0,
        product: parseInt(this.dialogPricing.product) || 0,
        vendor: parseInt(this.dialogPricing.selected.id) || 0,
        city_origin: this.newShippingOrigin || 0,
        city_destination: this.newShippingDestination || 0,
        price: this.newShippingPrice || 0,
        type: this.newShippingType || 'KG',
        notes: this.newShippingNotes || ''
      }
      if (params.vendor !== 0 && !params.vendor) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Vendor ID!' })
        return false
      }
      if (!params.id) {
        delete params.id
      }
      if (!parseInt(params.city_origin)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan asal pengiriman!' })
        return false
      }
      if (!parseInt(params.city_destination)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan tujuan pengiriman!' })
        return false
      }
      if (!parseInt(params.price)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tentukan harga pengiriman!' })
        return false
      }
      if (!params.notes) {
        this.$store.dispatch('TOAST', { show: true, message: 'Catatan harus di isi!' })
        return false
      }
      if (!parseInt(params.product) && !parseInt(this.dialogPricing.selected.type)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Produk belum dipilih!' })
        return false
      }
      const estTo = prompt('Estimasi Pengiriman (hari) :', 0)
      const weightMinimum = prompt('Weight Minimum (Kg) :', 0)
      if (!parseInt(estTo) || !parseInt(weightMinimum)) {
        return false
      } else {
        params.est_to = parseInt(estTo)
        params.weight_minimum = (parseFloat(weightMinimum) * 1000) || 0
      }
      if (!confirm(params.id ? 'Update data ?' : 'Tambah data ?')) {
        return false
      }
      this.$store.dispatch('logistic/SHIPPING_PRICING_PROCESS', params)
        .then((res) => {
          if (res.status) {
            this.LoadPricing(this.dialogPricing.selected)
            this.ResetNewShippingPrice()
            this.dialogPricing.show = false
          }
        })
    },
    LoadPricing (item) {
      if (item) {
        if (loadingTimeout) {
          clearTimeout(loadingTimeout)
        }
        loadingTimeout = setTimeout(() => {
          const productFilter = parseInt(this.dialogPricing.productFilter) ? ('&product=' + parseInt(this.dialogPricing.productFilter)) : ''
          const customerFilter = parseInt(this.dialogPricing.customerFilter) ? ('&customer=' + parseInt(this.dialogPricing.customerFilter)) : ''
          this.loading_pricing = true
          this.$store.dispatch('logistic/SHIPPING_PRICING_GET', {
            vendor: item.id,
            q: '?city_origin=' + (parseInt(this.dialogPricing.shipping_origin) || 0) + '&city_destination=' + (parseInt(this.dialogPricing.shipping_destination) || 0) + productFilter + customerFilter
          })
            .then((res) => {
              this.dialogPricing.data = (res.data.data || []).map((r) => {
                r.weight_minimum_kg = parseInt(r.weight_minimum) ? parseFloat(parseFloat(parseInt(r.weight_minimum) / 1000).toFixed(1)) : 0
                return r
              })
              this.loading_pricing = false
            })
        }, 300)
      }
    },
    AREA_REQUEST (isOrigin, svpOrigin) {
      let q = '?nonKecamatan=1&q='
      if (isOrigin === 3) {
        q += this.newShippingDestinationQuery
      } else if (isOrigin === 2 || svpOrigin) {
        q += (svpOrigin || this.newShippingOriginQuery)
        if (svpOrigin) {
          this.newShippingOriginQuery = svpOrigin
        }
      } else if (isOrigin) {
        q += (svpOrigin || this.shippingOriginQuery)
        if (svpOrigin) {
          this.shippingOriginQuery = svpOrigin
        }
      } else {
        q += this.shippingDestinationQuery
      }
      this.$store.dispatch('AREA_GET', q + (isOrigin ? '&origin=1' : '')).then((res) => {
        if (res.status) {
          if (isOrigin === 3) {
            this.newShippingDestinationData = res.data.data || []
          } else if (isOrigin === 2) {
            this.newShippingOriginData = res.data.data || []
          } else if (isOrigin) {
            this.shippingOriginData = res.data.data || []
          } else {
            this.shippingDestinationData = res.data.data || []
          }
        }
      })
    },
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '') + '&nonKecamatan=1').then((res) => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    STORE_SHOW (store) {
      if (store) {
        this.dialogStore.data = Object.assign({}, store)
        if (store.area_name) {
          this.areaquery = store.area_name || ''
        }
      } else {
        this.dialogStore.data = {
          id: null,
          name: '',
          type: 0,
          type_shipping: '',
          code: '',
          phone: '',
          instagram: '',
          description: '',
          faq: '',
          tnc: '',
          pp: '',
          address: '',
          city_id: 0,
          status: 0
        }
      }
      this.dialogStore.show = true
    },
    STORE_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      const isVendor = this.storeType > -1 ? (parseInt(this.storeType) || 0) : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + ((sortBy && sortBy.length) ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('STORE', query + (q ? ('&q=' + q) : '') + ('&isVendor=' + isVendor) + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          if (this.$store.state.user.role !== 'root' && this.$store.state.user.role !== 'admin') {
            this.table = res.data.data.filter(r => parseInt(r.id) === parseInt(this.$store.state.user.store))
          } else {
            this.table = res.data.data
          }
          this.tableTotal = (res.data.data && res.data.data.length) || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    STORE_PROCESS () {
      const processedStore = Object.assign({}, this.dialogStore.data)
      if (!processedStore.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!processedStore.code) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan kode yang valid!' })
        return false
      }
      if (!this.$validatePhone(processedStore.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No Telepon tidak valid!' })
        return false
      }
      if (!parseInt(processedStore.city_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Area belum dipilih!' })
        return false
      }
      processedStore.phone = this.$reformatPhone(processedStore.phone)
      delete processedStore.city_name
      delete processedStore.city_type
      delete processedStore.province_name
      this.$store.dispatch('STORE_PROCESS', processedStore)
        .then((res) => {
          if (res.status) {
            if (res.data.data === 'code_already_exist') {
              this.$store.dispatch('TOAST', { show: true, message: 'Kode cabang/vendor sudah pernah digunakan!' })
            } else {
              this.STORE_GET(this.tableSearch)
              this.dialogStore.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedStore.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ResetNewShippingPrice () {
      this.newShippingOrigin = 0
      this.newShippingDestination = 0
      this.newShippingPrice = 0
      this.newShippingCustomer = 0
      this.newShippingType = 'KG'
      this.newShippingNotes = ''
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'banner'
          }).then((resUpload) => {
            console.log(resUpload)
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogStore.data.welcome_banner = resUpload.data.data.secure_url
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
